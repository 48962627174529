<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    hide-default-footer
    :items-per-page="10000"
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    ></v-progress-linear>

    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="word-no-wrap">
            {{ item.date | utc_to_local_datetime("DD-MM-YYYY") }}
          </td>
          <td class="text-right">{{ item.clicks | local_numbers }}</td>
          <td class="text-right">{{ item.unique_clicks | local_numbers }}</td>
          <td class="text-right">{{ item.sales | local_numbers }}</td>
          <td class="text-right">{{ item.conversion | local_numbers }} %</td>
          <td class="text-right">{{ item.value | local_numbers }}</td>
          <td class="text-right">{{ item.commission | local_numbers }}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: "Dato",
        align: "left",
        value: "date"
      },
      {
        text: "Klik",
        align: "right",
        value: "clicks"
      },
      {
        text: "Unikke klik",
        align: "right",
        value: "unique_clicks"
      },
      {
        text: "Salg",
        align: "right",
        value: "sales"
      },
      {
        text: "Konvertering",
        align: "right",
        value: "conversion"
      },
      {
        text: "Omsætning",
        align: "right",
        value: "value"
      },
      {
        text: "Kommission",
        align: "right",
        value: "commission"
      }
    ]
  })
};
</script>
