<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Daglig rapport</div>
          <span class="subtitle-1 light-grey"
            >Oversigt over præstation pr. dag</span
          >
        </div>
        <v-spacer></v-spacer>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              outlined
              color="primary"
              @click="
                filtersTmp = JSON.parse(JSON.stringify(filters));
                filterDialog = true;
              "
            >
              <!--<v-icon small>fal fa-cog</v-icon>-->
              <v-icon small>fas fa-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtrér</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <daily-stats-data-table
          :items="stats"
          :loading="loading"
        ></daily-stats-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filtrér rapport
        </v-card-title>
        <v-card-text>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Periode start"
            cancel-button
            reset-button
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="Periode slut"
            cancel-button
            reset-button
          ></date-picker>
          <v-flex class="mt-2">
            <business-autocomplete
              v-model="filtersTmp.business_uuid"
              :return-object="false"
            ></business-autocomplete>
          </v-flex>
          <v-flex class="mt-2">
            <influencer-autocomplete
              v-model="filtersTmp.influencer_uuid"
              :return-object="false"
            ></influencer-autocomplete>
          </v-flex>
          <v-flex class="mt-2">
            <campaign-autocomplete
              v-model="filtersTmp.campaign_uuid"
              :return-object="false"
            ></campaign-autocomplete>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = JSON.parse(JSON.stringify(filtersTmp));
              filterDialog = false;
            "
          >
            Filtrér
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import DatePicker from "@/components/common/filters/DatePicker";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import DailyStatsDataTable from "../data-tables/DailyStatsDataTable";
import InfluencerAutocomplete from "../../autocompletes/InfluencerAutocomplete";
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
import CampaignAutocomplete from "../../autocompletes/CampaignAutocomplete";
import { mapActions } from "vuex";

export default {
  components: {
    DailyStatsDataTable,
    DatePicker,
    BusinessAutocomplete,
    InfluencerAutocomplete,
    CampaignAutocomplete
  },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data: () => ({
    loading: true,
    stats: [],
    filterDialog: false,
    filtersTmp: {},
    filters: {}
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", { loadStats: "getStats" }),
    getStats() {
      this.loading = true;
      let filters = this.cloneDeep(this.filters);
      let params = {
        ...filters,
        group: ["date"]
      };
      this.loadStats(params).then(stats => {
        this.loading = false;
        this.stats = stats;
      });
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment().format("YYYY-MM-DD");
    } else {
      this.getStats();
    }
  }
};
</script>
